import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "caret"]

  toggle() {
    this.contentTarget.classList.toggle("hidden")
    this.updateCaret()
  }

  updateCaret() {
    if (this.contentTarget.classList.contains("hidden")) {
      this.caretTarget.textContent = "▶" // Caret pointing right
    } else {
      this.caretTarget.textContent = "▼" // Caret pointing down
    }
  }
}
